@font-face {
  font-family: 'Flexo-Regular';
  src: url('assets/fonts/285B7A_1_0.eot');
  src: url('assets/fonts/285B7A_1_0.eot?#iefix') format('embedded-opentype'), url('assets/fonts/285B7A_1_0.woff') format('woff'), url('assets/fonts/285B7A_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Flexo-Bold';
  src: url('assets/fonts/285B7A_5_0.eot');
  src: url('assets/fonts/285B7A_5_0.eot?#iefix') format('embedded-opentype'), url('assets/fonts/285B7A_5_0.woff') format('woff'), url('assets/fonts/285B7A_5_0.ttf') format('truetype');
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.body-wrapper {
  margin-left: 13px;
  margin-right: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 355px;
  width: 100%;
}
