:root {
  --red: #c33527;
  --green: #4dae5b;
  --blue: #32a7d9;
  --yellow: #e6bd2f;
  --white: #fff;
  --lightest-grey: #f3f3f3;
  --lighter-grey: #a4a4a4;
  --light-grey: #9a9a9a;
  --dark-grey: #616161;
  --darker-grey: #313131;
  --darkest-grey: #212529;
  --disabled-bg: #ddd;
}

@font-face {
  font-family: 'Flexo-Regular';
  src: url('https://static.prod.pokemon.com/fonts/flexo-regular.eot');
  src: url('https://static.prod.pokemon.com/fonts/flexo-regular.eot?#iefix') format('embedded-opentype'), url('https://static.prod.pokemon.com/fonts/flexo-regular.woff') format('woff'), url('https://static.prod.pokemon.com/fonts/flexo-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Flexo-Bold';
  src: url('https://static.prod.pokemon.com/fonts/flexo-bold.eot');
  src: url('https://static.prod.pokemon.com/fonts/flexo-bold.eot?#iefix') format('embedded-opentype'), url('https://static.prod.pokemon.com/fonts/flexo-bold.woff') format('woff'), url('https://static.prod.pokemon.com/fonts/flexo-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Flexo-Heavy';
  src: url('https://static.prod.pokemon.com/fonts/flexo-heavy.eot');
  src: url('https://static.prod.pokemon.com/fonts/flexo-heavy.eot?#iefix') format('embedded-opentype'), url('https://static.prod.pokemon.com/fonts/flexo-heavy.woff') format('woff'), url('https://static.prod.pokemon.com/fonts/flexo-heavy.ttf') format('truetype');
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  line-height: 1.5;
}

body #root {
  align-self: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
    text-align: center;
    display:flex;
    flex-direction: column;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.left-aligned {
  text-align: left;
}

.secondary-button {
  background-color: #6495ed;
}

.primary-button {
  background-color: #4dad5b;
}

.box-label {
  font-size: 11px;
  font-family: "Flexo-Bold", serif;
  margin-bottom: 0;
}

.login-info>h1,
h1.header {
  font-family: "Flexo-Heavy", serif;
  color: #000;
  text-align: center;
  font-size: 24px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  margin-top: 0px;
}

label.primary {
  font-size: 14px;
  font-family: "Flexo-Regular", serif;
  text-align: center;
}

p.emphasis {
  font-size: 14px;
  font-family: "Flexo-Bold", serif;
  text-align: left;
  margin-top: 10px;
}

p.secondary {
  font-family: "Flexo-Regular", serif;
  margin-bottom: '10px';
  text-align: center;
  font-size: 14px;
  color:#000;
}

p.tertiary {
  font-family: "Flexo-Regular", serif;
  text-align: left;
  font-size: 12px;
  font-style: italic;
  color: var(--light-grey);
}

ul.bulleted {
  font-family: "Flexo-Regular", serif;
  padding-bottom: 5px;
  margin: 0px;
  padding-left: 0px;
  list-style-position: inside;
  text-align: left;
  font-size: 12px;
  color: var(--light-grey);
}

a.link, a.link:hover {
  color: #31A7D9;
}

.body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Flexo-Regular", serif;
}

.footer-wrapper {
  width: 100%;
  display: flex;
  font-size: 10px;
  font-family: "Flexo-Regular", serif;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  color: #4e4e4e;
  text-align: center;
  white-space: pre-line;
}

.copyright { /* removed some padding since positioning isn't absolute */
  color: #4e4e4e;
  font-family: "Flexo-Regular", serif;
  text-align: center;
  white-space: pre-line;
  margin: 2px;
  margin-bottom: 10px;
}

.accurate-info {
  font-style: italic;
  font-size: 14px;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 10px;
}

.resend-code-button {
  cursor: pointer;
  color: #31A7D9;
  border: none;
  background: none;
  float: right;
  font-size: 12px;
}

.button-disabled {
  background-color: #a4a4a4;
  color: #616161;
  pointer-events: none;
}

.link-disabled {
  color: var(--dark-grey);
}

.document-card {
  height: 15rem;
  white-space: pre-wrap;
  text-align: left;
  font-size: .75rem;
  margin-bottom: 20px;
}

.document-header {
  font-family: "Flexo-Heavy", serif;
  color: #000;
  text-align: center;
  font-size: 24px;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 16px;
}

.document-buttons{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.hidden {
  display: none;
}

.title-wrapper h1 {
  font-family: "Flexo-Heavy", serif;
  margin: 0;
  text-align: center;
  font-size: 24px;
}

p {
  margin: 0;
  font-size: 14px;
}

.title-wrapper {
  margin-bottom: 10px;
}

.sub-title {
  margin-top: 5px;
  font-size: 14px;
}

body {
  font-size: 0.875rem;
}

.grey-callout-box {
  border-radius: 10px;
  background: var(--lightest-grey);
  padding: 20px
}
