.form-row {
    padding-bottom: 15px;
}

.age-gate-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}

.age-gate-form .country_select {
    text-align: left;
}

.modal-contents {
    font-family: 'Flexo-Regular', serif;
}